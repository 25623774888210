export type Track = { file: string; title: string };

export const musicTracks = ['alright', 'fifties', 'mozart', 'whisper'] as const;
export type MusicKey = (typeof musicTracks)[number];
export const PreferredMusicTrack: MusicKey = 'mozart';
export const musicTracksLookup: Record<MusicKey, Track> = {
  alright: {
    file: '/shared/music/its-gonna-be-alright.mp3',
    title: "It's Gonna Be Alright",
  },
  fifties: {
    file: '/shared/music/the-fifties.mp3',
    title: 'The Fifties',
  },
  mozart: {
    file: '/shared/music/mozartSerenade.mp3',
    title: 'Mozart Serenade',
  },
  whisper: {
    file: '/shared/music/whisper.mp3',
    title: 'Whisper',
  },
};
