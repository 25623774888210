import { memo } from 'react';

import type { IconBaseProps } from '../IconType';
import { sizeVariants } from '../IconType';

export const ArrowDown = memo((props: IconBaseProps) => {
  const computedSize = sizeVariants[props.size || 'base'];
  const computedColor = props.color || 'black';
  return (
    <svg
      fill="currentColor"
      height={computedSize}
      stroke="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 17"
      width={computedSize}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.09 6.73516L16.7433 2.08184C17.468 1.3575 18.4506 0.950587 19.4752 0.950586C20.4998 0.950586 21.4825 1.3575 22.2071 2.08184C22.9315 2.80649 23.3384 3.78914 23.3384 4.81373C23.3384 5.83832 22.9315 6.82097 22.2071 7.54561L17.5538 12.1968L14.4673 15.2834C14.1551 15.5956 13.7845 15.8432 13.3766 16.0122C12.9687 16.1811 12.5315 16.2681 12.09 16.2681C11.6485 16.2681 11.2114 16.1811 10.8035 16.0122C10.3956 15.8432 10.025 15.5956 9.71281 15.2834L6.62458 12.1968L0.156024 5.72786L5.61938 0.264502L12.09 6.73516Z"
        fill={computedColor}
      />
    </svg>
  );
});
