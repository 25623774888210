import { Box, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import { ArrowDown, Pause, Play } from '@ours/web-icons';
import { useMachine } from '@xstate/react';
import type { FC, MouseEventHandler } from 'react';
import { memo, useEffect, useState } from 'react';

import { Button } from '../../components/Buttons/Button';
import { Slider } from '../../components/Inputs/Slider';
import { useEvent } from '../../hooks/useEvent';

import type { MusicKey } from './lib/constants';
import { musicTracks, musicTracksLookup } from './lib/constants';
import { musicPlayerMachine } from './lib/musicPlayerMachine';

export interface MusicPlayerProps {}

export const MusicPlayer: FC<MusicPlayerProps> = memo(() => {
  const [state, send] = useMachine(musicPlayerMachine);
  const [isRendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, []);
  const onPlay = useEvent(async () => {
    send('PLAY');
  });

  const onPause = useEvent(() => {
    send('PAUSE');
  });

  const onSliderChange = useEvent((volume: number) => {
    send({ type: 'ADJUST_AUDIO', volume: volume / 100 });
  });

  const onSetTrack: MouseEventHandler<any> = useEvent((e) => {
    const _value = e.currentTarget.dataset.value;
    const track: MusicKey = _value in musicTracksLookup ? _value : 'mozart';
    send({ track, type: 'SWITCH_TRACK' });
  });

  return (
    <Flex flexDir="column" gap="100" maxW="300px">
      <Flex w="full">
        <Menu>
          <MenuButton
            _focus={{ outline: 'blue 1px solid' }}
            _hover={{ color: 'brand.blueHover' }}
            bg="white"
            border={Border}
            borderBottomLeftRadius="500"
            borderBottomRightRadius="500"
            w="full"
          >
            <Box
              alignItems="center"
              display="flex"
              fontSize="2xs"
              justifyContent="space-between"
              px="200"
              py="50"
            >
              <Box>
                {isRendered ? musicTracksLookup[state.context.selectedTrack].title : 'Select music'}
              </Box>
              <Box pb="1px">
                <Button as="div" size="roundSm">
                  <ArrowDown size="xs" />
                </Button>
              </Box>
            </Box>
          </MenuButton>
          <MenuList border="2px solid black" borderRadius="500" minW="0" zIndex={2}>
            {musicTracks.map((track) => (
              <MenuItem
                _focus={{ bg: 'brand.blueHover', color: 'white' }}
                _hover={{ bg: 'brand.blueHover', color: 'white' }}
                color="black"
                data-value={track}
                fontSize="300"
                key={track}
                onClick={onSetTrack}
                px="400"
                py="2px"
              >
                <Box>{musicTracksLookup[track].title}</Box>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>

      <Flex color="black" justifyContent="center" w="full">
        <Box
          aria-label="play audio"
          as="button"
          onClick={state.context.isPlaying ? onPause : onPlay}
          sx={{ path: { fill: 'black' } }}
          title="Play audio"
          type="button"
          w={{ base: '18px', md: '24px' }}
        >
          {state.context.isPlaying ? <Pause /> : <Play />}
        </Box>

        <Box flexGrow={1} pl="100" pr="100" pt="3px" w="full">
          <Slider
            aria-label="volume slider"
            defaultValue={30}
            filledColor="black"
            max={100}
            min={0}
            onChange={onSliderChange}
            unfilledColor="white"
            value={(state.context.volume || 0) * 100}
          />
        </Box>
      </Flex>
    </Flex>
  );
});
MusicPlayer.displayName = 'MusicPlayer';
