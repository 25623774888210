import type { ToXStateSchema } from '@ours/types';
import { assertType, max, noop } from '@ours/utils';
import type { MachineConfig } from 'xstate';
import { assign, createMachine } from 'xstate';

interface JournalContext {
  isCounting: boolean;
  timeInSec: number;
  timeRemainingInSec: number;
}

const baseContext: JournalContext = {
  isCounting: false,
  timeInSec: 10,
  timeRemainingInSec: 10,
};

type IncrementDurationAction = { type: 'INCREMENT_DURATION' };
type AddTime = { type: 'ADD_TIME' };
type SubtractTime = { type: 'SUBTRACT_TIME' };
type StartAction = { timeInSec: number; type: 'START_ACTION' };
type ReStartAction = { type: 'RESTART_ACTION' };
type PauseAction = { type: 'PAUSE_ACTION' };
type ResumeAction = { type: 'RESUME_ACTION' };
export type JournalActions =
  | StartAction
  | ReStartAction
  | PauseAction
  | ResumeAction
  | IncrementDurationAction
  | AddTime
  | SubtractTime;
export type JournalActionTypes = JournalActions['type'];
export const JournalStates = ['init', 'journaling', 'complete'] as const;
export type JournalStates = (typeof JournalStates)[number];

const RESTART_ACTION = { actions: 'restart', target: 'journaling' };
const ADD_TIME = { actions: 'addTime' };
const SUBTRACT_TIME = { actions: 'subtractTime' };
const journalMachineSchema: MachineConfig<
  JournalContext,
  ToXStateSchema<typeof JournalStates>,
  JournalActions
> = {
  context: baseContext,
  description: '',
  id: 'journalMachineSchema',
  initial: 'init',
  predictableActionArguments: true,
  states: {
    complete: {
      on: { RESTART_ACTION },
    },
    init: {
      on: { ADD_TIME, START_ACTION: { actions: 'start', target: 'journaling' }, SUBTRACT_TIME },
    },
    journaling: {
      invoke: [
        {
          description: 'Countdowns',
          src: () => (cb) => {
            const id = setInterval(() => {
              cb({ type: 'INCREMENT_DURATION' });
            }, 1000);
            return () => {
              clearInterval(id);
            };
          },
        },
      ],
      on: {
        INCREMENT_DURATION: [
          { actions: noop, cond: 'isPaused' },
          { cond: 'isCompleted', target: 'complete' },
          { actions: 'incDuration' },
        ],
        PAUSE_ACTION: { actions: 'pause', cond: 'isRunning' },
        RESTART_ACTION,
        RESUME_ACTION: { actions: 'resume', cond: 'isPaused' },
      },
    },
  },
};

export const journalMachine = createMachine(journalMachineSchema, {
  actions: {
    addTime: assign((ctx) => {
      const timeInSec = ctx.timeInSec + 30;
      return {
        timeInSec,
        timeRemainingInSec: timeInSec,
      };
    }),
    incDuration: assign((ctx) => {
      return {
        timeRemainingInSec: max(ctx.timeRemainingInSec - 1, 0),
      };
    }),
    pause: assign((ctx) => {
      return { ...ctx, isCounting: false };
    }),
    restart: assign((ctx) => {
      return {
        isCounting: true,
        timeRemainingInSec: ctx.timeInSec,
      };
    }),
    resume: assign((ctx) => {
      return { ...ctx, isCounting: true };
    }),
    start: assign((_, ev) => {
      assertType(ev, 'START_ACTION');
      return {
        isCounting: true,
        timeInSec: ev.timeInSec || 10,
        timeRemainingInSec: ev.timeInSec || 10,
      };
    }),
    subtractTime: assign((ctx) => {
      const timeInSec = max(ctx.timeInSec - 30, 30);
      return {
        timeInSec,
        timeRemainingInSec: timeInSec,
      };
    }),
  },
  guards: {
    isCompleted: (ctx) => ctx.timeRemainingInSec <= 0,
    isPaused: (ctx) => ctx.isCounting === false,
    isRunning: (ctx) => ctx.isCounting,
  },
});
