import type { Maybe } from '@ours/types';
import type { BrandColors } from '@ours/utils';
import { useMemo } from 'react';

export const colorEnum = (prefColor: Maybe<BrandColors>, defaultColor: BrandColors) =>
  prefColor ? `brand.${prefColor}` : `brand.${defaultColor}`;

export const useColorEnum = (prefColor: Maybe<BrandColors>, defaultColor: BrandColors) => {
  return useMemo(() => colorEnum(prefColor, defaultColor), [prefColor, defaultColor]);
};
