import { Box } from '@chakra-ui/react';
import { Colors } from '@ours/utils';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export interface CountdownCircleV2Props {
  children?: ReactNode;
  durationInSec: number;
  elapsedTimeInSec: number;
  rotation?: 'clockwise' | 'counterclockwise';
  size: number;
  strokeColor?: string;
  strokeLinecap?: 'round' | 'square' | 'butt';
  strokeWidth?: number;
  trailColor?: string;
  trailStrokeWidth?: number;
}

export const CountdownCircle: FC<CountdownCircleV2Props> = memo(
  ({
    children,
    durationInSec,
    elapsedTimeInSec,
    rotation = 'clockwise',
    size,
    strokeColor,
    strokeLinecap = 'round',
    strokeWidth = 12,
    trailColor,
    trailStrokeWidth,
  }) => {
    const maxStrokeWidth = Math.max(strokeWidth, trailStrokeWidth ?? 0);
    const { path, pathLength } = getPathProps(size, maxStrokeWidth, rotation);
    const strokeDashoffset = linearEase(elapsedTimeInSec, 0, pathLength, durationInSec);

    return (
      <Box
        h={`${size}px`}
        pos="relative"
        sx={{ path: { transition: 'all ease 1s' } }}
        w={`${size}px`}
      >
        <svg height={size} width={size} xmlns="http://www.w3.org/2000/svg">
          <path
            d={path}
            fill="none"
            stroke={trailColor ?? '#d9d9d9'}
            strokeWidth={trailStrokeWidth ?? strokeWidth}
          />
          <path
            d={path}
            fill="none"
            stroke={strokeColor || Colors.brand.pink}
            strokeDasharray={pathLength}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap={strokeLinecap ?? 'round'}
            strokeWidth={strokeWidth}
          />
        </svg>
        {children ? (
          <Box
            alignItems={'center'}
            display={'flex'}
            height={'100%'}
            justifyContent={'center'}
            left={0}
            position={'absolute'}
            top={0}
            width={'100%'}
          >
            {children}
          </Box>
        ) : null}
      </Box>
    );
  }
);

const linearEase = (elapsedTime: number, start: number, goal: number, duration: number) => {
  if (duration === 0) {
    return start;
  }

  const currentTime = elapsedTime / duration;
  return start + goal * currentTime;
};

export const getPathProps = (
  size: number,
  strokeWidth: number,
  rotation: 'clockwise' | 'counterclockwise'
) => {
  const halfSize = size / 2;
  const halfStrokeWith = strokeWidth / 2;
  const arcRadius = halfSize - halfStrokeWith;
  const arcDiameter = 2 * arcRadius;
  const rotationIndicator = rotation === 'clockwise' ? '1,0' : '0,1';

  const pathLength = 2 * Math.PI * arcRadius;
  const path = `m ${halfSize},${halfStrokeWith} a ${arcRadius},${arcRadius} 0 ${rotationIndicator} 0,${arcDiameter} a ${arcRadius},${arcRadius} 0 ${rotationIndicator} 0,-${arcDiameter}`;

  return { path, pathLength };
};
