import type { ToXStateSchema } from '@ours/types';
import { assertType } from '@ours/utils';
import type { MachineConfig } from 'xstate';
import { assign, createMachine } from 'xstate';

import type { MusicKey } from './constants';
import { musicTracksLookup } from './constants';

interface MusicPlayerContext {
  audio: HTMLAudioElement | null;
  isPlaying: boolean;
  selectedTrack: MusicKey;
  // 0->1
  volume: number;
}

const baseContext: MusicPlayerContext = {
  audio: null,
  isPlaying: false,
  selectedTrack: 'mozart',
  volume: 1,
};

type PlayAction = { type: 'PLAY' };
type PauseAction = { type: 'PAUSE' };
type AdjustAudioAction = { type: 'ADJUST_AUDIO'; volume: number };
type SwitchTrack = { track: MusicKey; type: 'SWITCH_TRACK' };
export type Actions = AdjustAudioAction | PlayAction | PauseAction | SwitchTrack;
export type ActionTypes = Actions['type'];
export const States = ['idle', 'playing', 'startPlaying'] as const;
export type States = (typeof States)[number];
const key = '@ours/preferred-music-track';
const SWITCH_TRACK = [
  { actions: 'switchTrack', cond: 'isPlaying', target: 'startPlaying' },
  { actions: 'switchTrack' },
];
const ADJUST_AUDIO = { actions: 'adjustAudio' };
const musicPlayerSchema: MachineConfig<
  MusicPlayerContext,
  ToXStateSchema<typeof States>,
  Actions
> = {
  context: baseContext,
  description: '',
  exit: (ctx) => ctx.audio?.pause(),
  id: 'musicPlayerSchema',
  initial: 'idle',
  predictableActionArguments: true,
  states: {
    idle: {
      invoke: {
        onDone: {
          actions: assign((_, event) => ({
            audio: null,
            isPlaying: false,
            selectedTrack: event.data,
          })),
        },
        src: async (ctx) => {
          if (ctx.audio && !ctx.audio.paused) {
            ctx.audio.pause();
          }
          const _value = localStorage.getItem(key);
          return _value && _value in musicTracksLookup ? _value : 'mozart';
        },
      },
      on: {
        ADJUST_AUDIO,
        PLAY: { target: 'startPlaying' },
        SWITCH_TRACK,
      },
    },
    playing: {
      invoke: {
        src: async (ctx) => () => {
          if (!ctx.audio) {
            return;
          }

          ctx.audio.onended = () => {
            ctx.audio?.play();
          };
        },
      },
      on: {
        ADJUST_AUDIO,
        PAUSE: { target: 'idle' },
        SWITCH_TRACK,
      },
    },
    startPlaying: {
      invoke: {
        onDone: {
          actions: assign((_, ev) => ({ audio: ev.data, isPlaying: true })),
          target: 'playing',
        },
        src: async (ctx) => {
          if (ctx.audio) {
            ctx.audio.pause();
          }

          const audio = new Audio();
          audio.src = musicTracksLookup[ctx.selectedTrack].file;
          audio.volume = ctx.volume;
          await audio.play();
          return audio;
        },
      },
    },
  },
};

export const musicPlayerMachine = createMachine(musicPlayerSchema, {
  actions: {
    adjustAudio: assign((ctx, ev) => {
      assertType(ev, 'ADJUST_AUDIO');

      if (ctx.audio) {
        ctx.audio.volume = ev.volume;
      }
      return { ...ctx, volume: ev.volume };
    }),
    switchTrack: assign((ctx, ev) => {
      assertType(ev, 'SWITCH_TRACK');
      localStorage.setItem(key, ev.track);
      return { ...ctx, selectedTrack: ev.track };
    }),
  },
  guards: {
    isPlaying: (ctx) => ctx.isPlaying,
  },
});
