import type { FC } from 'react';
import React, { memo } from 'react';

export const Pause: FC = memo(() => {
  return (
    <svg fill="none" height="20" viewBox="0 0 15 20" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1655 -1.05367e-07C13.5462 -4.71745e-08 14.6655 1.11929 14.6655 2.5L14.6655 20L9.66553 20L9.66553 2.5C9.66553 1.11929 10.7848 -1.6356e-07 12.1655 -1.05367e-07Z"
        fill="white"
      />
      <path
        d="M2.5 -1.05367e-07C3.88071 -4.71745e-08 5 1.11929 5 2.5L5 20L-1.02175e-06 20L-2.84181e-07 2.5C-2.25988e-07 1.11929 1.11929 -1.6356e-07 2.5 -1.05367e-07Z"
        fill="white"
      />
    </svg>
  );
});
