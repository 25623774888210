import type { FC } from 'react';
import React, { memo } from 'react';

export const Play: FC = memo(() => {
  return (
    <svg fill="none" height="23" viewBox="0 0 17 23" width="17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7165 8.92854C16.6574 10.0939 16.6574 12.9071 14.7165 14.0725L0.250001 22.7588L0.250002 0.242187L14.7165 8.92854Z"
        fill="white"
      />
    </svg>
  );
});
