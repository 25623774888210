import type { SliderProps } from '@chakra-ui/react';
import {
  Slider as ChakraSlider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

export const Slider: FC<SliderProps & { filledColor?: string; unfilledColor?: string }> = memo(
  ({ filledColor, unfilledColor, ...props }) => {
    return (
      <ChakraSlider {...props}>
        <SliderTrack bg={unfilledColor ? unfilledColor : undefined}>
          <SliderFilledTrack bg={filledColor ? filledColor : 'brand.pink'} />
        </SliderTrack>
        <SliderThumb />
      </ChakraSlider>
    );
  }
);
