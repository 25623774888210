export const RoundedCorners = {
  Bottom: {
    borderBottomLeftRadius: '700',
    borderBottomRightRadius: '700',
  },
  Rotating: {
    borderBottomRightRadius: '700',
    borderTopLeftRadius: '700',
  },
  Rotating55: {
    borderBottomRightRadius: '55px',
    borderTopLeftRadius: '55px',
  },
  RotatingLg: {
    borderBottomRightRadius: '800',
    borderTopLeftRadius: '800',
  },
  Top: {
    borderTopLeftRadius: '700',
    borderTopRightRadius: '700',
  },
};
