import { floor } from '../math/floor';

export const toDaysHoursMinutesSeconds = (totalSeconds: number): IToDHMSResponse => {
  const days = `${floor(totalSeconds / (3600 * 24))}`;
  const hours = `${floor((totalSeconds % (3600 * 24)) / 3600)}`;
  const minutes = `${floor((totalSeconds % 3600) / 60)}`.padStart(2, '0');
  const seconds = `${floor(totalSeconds % 60)}`.padStart(2, '0');

  return { days, hours, minutes, seconds };
};

export interface IToDHMSResponse {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}
