import type { ReactNode, SVGAttributes } from 'react';

export interface IconBaseProps extends SVGAttributes<SVGElement> {
  children?: ReactNode;
  color?: string;
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl';
}
export const sizeVariants: Record<NonNullable<IconBaseProps['size']>, string> = {
  '2xl': '48px',
  base: '18px',
  lg: '24px',
  sm: '14px',
  xl: '32px',
  xs: '12px',
};
